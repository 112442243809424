define([], function() {

  

  var queryString = {

    extract: function(str) {
      return str.split('?')[1] || '';
    },

    parse: function(str) {
      var ret = Object.create(null);

      if (typeof str !== 'string') {
        return ret;
      }

      str = str.trim().replace(/^(\?|#|&)/, '');

      if (!str) {
        return ret;
      }

      str.split('&').forEach(function(param) {
        var parts = param.replace(/\+/g, ' ').split('=');

        var key = parts.shift();
        var val = parts.length > 0 ? parts.join('=') : undefined;

        key = decodeURIComponent(key);

        val = val === undefined ? null : decodeURIComponent(val);

        if (ret[key] === undefined) {
          ret[key] = val;
        } else if (Array.isArray(ret[key])) {
          ret[key].push(val);
        } else {
          ret[key] = [ret[key], val];
        }
      });

      return ret;
    },

    stringify: function(obj, opts) {
      opts = opts || {};

      return obj ? Object.keys(obj).sort().map(function(key) {
        var val = obj[key];

        if (val === undefined) {
          return '';
        }

        if (val === null) {
          return key;
        }

        if (Array.isArray(val)) {
          var result = [];

          val.slice().sort().forEach(function(val2) {
            if (val2 === undefined) {
              return;
            }

            if (val2 === null) {
              result.push(encodeURIComponent(key));
            } else {
              result.push(encodeURIComponent(key) + '=' + encodeURIComponent(val2));
            }
          });

          return result.join('&');
        }

        return encodeURIComponent(key) + '=' + encodeURIComponent(val);
      }).filter(function(x) {
        return x.length > 0;
      }).join('&') : '';
    }
  };

  return queryString;

});
